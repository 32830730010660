@tailwind base;
@tailwind components;
@tailwind utilities;

/*@font-face {*/
/*    font-family: "Cafe24 Ohsquare";*/
/*    src: url("./fonts/Cafe24Ohsquare.ttf") format("truetype");*/
/*}*/

@font-face {
   font-family: 'GangwonEduHyeonokT';
    src: url("https://cdn.jsdelivr.net/gh/wooin21/web/fonts/GWedu/GangwonEduHyeonokT.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'GangwonEduPowerExtraBoldA';
    src: url(./fonts/GangwonEduPowerExtraBoldA.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}

body {
    /*background-color: #475ed7;*/
    margin: 0;
    font-family: "Noto Sans KR", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*overflow: hidden;*/
    scroll-behavior: smooth;
}

#root {
    /*position: fixed;*/
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.header-wrap {
    @apply fixed w-screen flex justify-between p-4 md:px-12 z-10 transition-colors ease-in-out;
}

.header-right {
    @apply flex items-center mt-auto;
}

.header-right.active .header-lang {
    @apply w-0;
}

.header-right.active .header-lang-list {
    @apply w-auto;
}

@layer utilities {
    .bg-gradient-to-148deg {
        /*noinspection CssUnresolvedCustomProperty*/
        background-image: linear-gradient(148deg, var(--tw-gradient-stops));
    }
}

.background {
    @apply bg-gradient-to-148deg from-[#4F34CF] to-[#035FF8];
}

section {
    @apply transform-gpu;
}

.scene1-window-frame {
    background-color: white;
}

.scene1-window-frame-1 {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 0;
    transition: width, height;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}

.scene1-window-frame-1.active {
    height: 15vh;
}

.scene1-window-frame-2 {
    position: absolute;
    left: 0;
    width: 0;
    height: 100vh;
    transition: width, height;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}

@media (min-width: 1024px) {
    .scene1-window-frame-2.active {
        width: 10vh;
    }
}

.scene1-window-frame-3 {
    position: absolute;
    right: 0;
    width: 0;
    height: 100vh;
    transition: width, height;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}

@media (min-width: 1024px) {
    .scene1-window-frame-3.active {
        width: 10vh;
    }
}

.scene1-window-frame-4 {
    position: absolute;
    bottom: 0;
    width: 100vw;
    height: 0;
    transition: width, height;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
}

.scene1-window-frame-4.active {
    height: 45vh;
}

.youtube-video-container {
    position: sticky;
    top: 0;
    @apply min-w-[100vw] min-h-[100vh] max-h-[100vh];
}

.youtube-video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

iframe.youtube-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

*::-webkit-scrollbar {
    /*display: none;*/
}
